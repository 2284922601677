<template>
  <div class="modal-p position-relative p-5">
    <div role="button" class="btn-close" @click="this.$emit('close')"></div>
    <div id="qr-code-full-region"></div>

    <div class="w-100 mt-2 mx-auto">
      <button class="stop-cam-btn" v-if="stopCam" @click="stopScan">
        <img src="@/assets/icones/camera_noflash_alt.svg" alt="" />
      </button>
    </div>
    <form>
      <row-component>
        <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 ">
          <label>Nº:</label>
          <label class="input-select-photo-label">
            <span @click="onCamera" class="input-photo-icone"
              ><img src="@/assets/icones/camera.svg"
            /></span>
            <input-component class="large-input" v-model:model-value="formData.code" />
          </label>
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label></label>
          <div class="input-group">
            <span
              class="input-group-text cursorPointer btn-search"
              id="basic-addon1"
              @click="getProduct()"
            >
              <i class="search icon"></i>
            </span>
          </div>
        </col-component>
      </row-component>
    </form>

    <div class="__table">
      <table class="table table-striped" v-if="formDateProduct">
        <thead>
          <tr>
            <th scope="col" style="width: 10%">Foto</th>
            <th scope="col" style="width: 40%">Nome</th>
            <th scope="col" style="width: 10%">Estoque</th>
            <th scope="col" style="width: 10%">Valor UN</th>
            <th scope="col" style="width: 10%">Quantidade</th>
            <th scope="col" style="width: 20%">Adicionar</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td data-label="Foto">
              <img class="rounded-circle" width="50" :src="this.formDateProduct.photo" alt="" />
            </td>
            <td data-label="Nome">{{ this.formDateProduct.name }}</td>
            <td data-label="Estoque">{{ this.formDateProduct.initial_inventory_store }}</td>
            <td data-label="Valor UN">R$ {{ this.formDateProduct.price }}</td>
            <td data-label="Quantidade" style="height: 70px">
              <input-component
                v-model:model-value="formDataSend.amount"
                @keypress="isNumber($event)"
              />
            </td>
            <td data-label="Adicionar">
              <button-submit icon="plus" @click="addProduct(this.formDateProduct.id)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import '@/assets/table.css';
import RowComponent from '@/components/row/RowComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import ColComponent from '@/components/row/ColComponent.vue';
import { useToast } from 'vue-toastification';

import { Html5Qrcode } from 'html5-qrcode';

const toast = useToast();

export default {
  name: 'ProductSearch',
  components: {
    ColComponent,
    ButtonSubmit,
    InputComponent,
    RowComponent
  },

  created() {
    this.checkSerach();
  },

  props: {
    productSerach: {
      required: false
    }
  },

  data() {
    return {
      formData: {
        code: '',
        name: ''
      },

      formDateProduct: '',
      formDataSend: {
        product_id: '',
        amount: 1
      },

      config: {
        fps: 10,
        qrbox: { width: 500, height: 150 }
      },
      stopCam: false
    };
  },

  methods: {
    checkSerach() {
      if (this.productSerach.barcode) {
        this.formData.code = this.productSerach.barcode;
        this.getProductClose();
      }
    },

    getProduct() {
      this.$store
        .dispatch('getProduct', this.formData)
        .then(r => {
          this.formDateProduct = r;
        })
        .catch(() => {
          toast.warning(`Nenhum produto foi encontrado com referência: ${this.formData.code}`, {
            timeout: 6000
          });
        });
    },

    getProductClose() {
      this.$store
        .dispatch('getProduct', this.formData)
        .then(r => {
          this.formDateProduct = r;
        })
        .catch(() => {
          toast.warning(`Nenhum produto foi encontrado com referência: ${this.formData.code}`, {
            timeout: 6000
          });
          setTimeout(() => {
            this.$emit('close');
          }, 1000);
        });
    },

    addProduct(id) {
      this.formDataSend.product_id = id;
      this.$store
        .dispatch('addProduct', this.formDataSend)
        .then(() => {
          this.formDateProduct = '';
          this.formData.code = '';
          toast.success('Produto Adicionado com sucesso', { timeout: 6000 });
        })
        .catch(() => {
          toast.error('Erro descohecido', { timeout: 6000 });
        });
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onCamera() {
      const html5QrCode = new Html5Qrcode('qr-code-full-region');
      const qrCodeSuccessCallback = decodedText => {
        html5QrCode.stop().then(() => {
          this.formData.code = decodedText;
          this.stopCam = false;
          this.getProduct();
        });
      };
      this.stopCam = true;
      html5QrCode.start({ facingMode: 'environment' }, this.config, qrCodeSuccessCallback);
    },

    stopScan() {
      const html5QrCode = new Html5Qrcode('qr-code-full-region');
      html5QrCode.clear();
      this.stopCam = false;
    }
  }
};
</script>

<style scoped>
.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 80%;
  overflow-y: auto;
  min-height: 250px;
  background: #ffffff;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.large-input {
  width: 100%;
  padding: 10px;
}

.btn-search {
  padding: 11.5px;
}

.input-qr-code-mobile {
  display: none;
}

.input-qr-code-desktop {
  display: block;
}

.stop-cam-btn {
  height: 42px;
  width: 42px;
  text-align: center;
  padding: 0.4rem;
  outline: none;
  border: 0;
  background-color: #000;
  position: relative;
}

.stop-cam-btn > img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(105%) contrast(100%);
}

@media (max-width: 991px) {
  .input-qr-code-mobile {
    display: block;
  }

  .input-qr-code-desktop {
    display: none;
  }
}
</style>
