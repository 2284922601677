<template>
  <div class="modal-window top-50 start-50 translate-middle">
    <div role="button" class="btn-close" @click="this.$emit('close')"></div>
    <row-component>
      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
        <div class="form-check form-check-inline">
          <input
            v-model="formDataClient.type"
            type="radio"
            value="1"
            name="docs"
            id="type-one"
            checked
          />
          <label class="form-check-label" for="type-one">CPF</label>
        </div>
        <div class="form-check form-check-inline">
          <input v-model="formDataClient.type" type="radio" value="2" name="docs" id="type-two" />
          <label class="form-check-label" for="type-two">CNPJ</label>
        </div>
      </col-component>
    </row-component>

    <row-component>
      <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
        <label>Nome:</label>
        <input-component v-model="formDataClient.name" :type="'text'" />
      </col-component>

      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
        <label>Apelido:</label>
        <input-component v-model="formDataClient.surname" :type="'text'" />
      </col-component>

      <col-component
        class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
        v-if="formDataClient.type === '2'"
      >
        <label>CNPJ:</label>
        <input-component
          v-model="formDataClient.cnpj"
          :type="'text'"
          v-mask="'##.###.###/####-##'"
        />
      </col-component>

      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
        <label>CPF:</label>
        <input-component v-model="formDataClient.cpf" :type="'text'" v-mask="'###.###.###-##'" />
      </col-component>

      <col-component
        class-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4 cursorPointer"
      >
        <button-submit icon="search icon" @click="submitSearch" />
      </col-component>
    </row-component>

    <div class="mt-4">
      <row-component>
        <col-component
          class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          v-for="(data, index) in order_client.data"
          :key="index"
        >
          <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
            <div class="h-100 avatar__image__div">
              <img class="avatar__image" v-if="data.photo" :src="data.photo" alt="" />
              <img class="avatar__image" v-else src="@/assets/avatar.png" alt="" />
            </div>
            <div class="ms-3 w-75">
              <p class="mb-1 text-truncate">Nome: {{ data.name }}</p>
              <p class="mb-1 text-truncate">Apelido: {{ data.surname }}</p>

              <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
            </div>
            <div class="position-absolute end-0 me-1 me-lg-3">
              <button-submit @click="selectClient(data)" icon="plus icon" />
            </div>
          </div>
        </col-component>
      </row-component>

      <button-pagination :pagination="order_client" :offset="3" @paginate="loadItens" />
    </div>
  </div>
</template>

<style scoped>
.modal-window {
  z-index: 6;
  width: 80%;
  padding: 2%;
  height: 60%;
  overflow: auto;
  position: fixed;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 5px 10px 12px 0 #a6a6a6;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.avatar__image {
  width: 50px;
}

.avatar__image__div {
  width: 10%;
}

@media (max-width: 991px) {
  .modal-window {
    width: 95%;
    height: 80%;
  }
}
</style>

<script>
import RowComponent from '@/components/row/RowComponent.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import buttonPagination from '@/components/buttons/ButtonPagination.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ColComponent from '@/components/row/ColComponent.vue';
import { mask } from 'vue-the-mask';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'ClientSearchModal',

  directives: {
    mask
  },

  created() {
    this.loadItens(1);
  },

  components: {
    RowComponent,
    ColComponent,
    buttonPagination,
    ButtonSubmit,
    InputComponent
  },

  data() {
    return {
      formDataClient: {
        page: '',
        type: '1',
        name: '',
        surname: '',
        cnpj: '',
        cpf: ''
      },

      searchSubmit: false
    };
  },

  methods: {
    loadItens(page) {
      this.$store
        .dispatch('loadOrderOrderClients', { ...this.params, page })
        .then(r => {
          if (this.searchSubmit) {
            this.searchSubmit = false;
            if (r.data.length === 0) {
              toast.info('Nada foi encontrado com order-service parâmetros informados!', {
                timeout: 1500
              });
            }
          }
        })
        .catch(() => {
          this.searchSubmit = false;
        });
    },

    submitSearch() {
      this.loadItens(1);
      this.searchSubmit = true;
    },

    selectClient(data) {
      this.$emit('close', data);
    }
  },

  computed: {
    order_client() {
      return this.$store.state.order.clients;
    },

    params() {
      return {
        page: this.order_client.current_page,
        type: this.formDataClient.type,
        name: this.formDataClient.name,
        cnpj: this.formDataClient.cnpj,
        cpf: this.formDataClient.cpf,
        surname: this.formDataClient.surname
      };
    }
  }
};
</script>
