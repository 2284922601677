<template>
  <div class="card main-card rounded-0">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="p-3 text-uppercase">
        <h5 class="text-dark">
          Caixa #{{ formData.code }} |
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status == 1">Aberto</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status == 2">Fechado</span>
        </h5>
        <div class="d-flex flex-column">
          <small>Hora de abertura: {{ formData.dateHourOpen }}</small>
          <small>Hora de fechamento: {{ formData.dateHourClose }}</small>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center m-3">
        <button-submit :icon="'print'" @click="openPrintClose(formData.id)" />
      </div>
    </div>

    <div class="spacing-border"></div>

    <div class="card m-3 rounded-0 text-uppercase">
      <div class="p-3 d-flex flex-column align-items-center align-items-md-start">
        <h5 class="text-uppercase">Consultora | Cliente</h5>
        <div class="d-flex align-items-center">
          <img
            class="character-label-icon-profile"
            :src="formData.user_photo"
            alt="foto_de_perfil"
          />
          <!-- <img class="character-label-icon-profile" src="../../assets/avatar.png" alt="foto_de_perfil"> -->
          <div>
            <small v-if="formData.user_creater">{{ formData.user_creater }} </small>
            <small v-else>N/I</small>
            |
            <small v-if="formData.name_client">{{ formData.name_client }} </small>
            <small v-else>N/I</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card m-3 rounded-0">
      <h5 class="p-3 text-uppercase">Produtos</h5>
      <div class="__table">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Motivo</th>
              <th scope="col">Data - Hora (Aberto)</th>
              <th scope="col">Forma de pagamento</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in formData.entrance_and_exit" :key="item.id">
              <td data-label="Tipo" v-if="item.type == 1">
                <i class="arrow alternate circle up icon green" />Entrada
              </td>
              <td data-label="Tipo" v-else>
                <i class="arrow alternate circle down icon red" /> Saída
              </td>
              <td data-label="Motivo">{{ item.reason }}</td>
              <td data-label="Data - Hora (Aberto)">{{ item.dateHourCreate }}</td>
              <td data-label="Forma de pagamento" v-if="item.type_payment == 1">Dinheiro</td>
              <td data-label="Forma de pagamento" v-if="item.type_payment == 2">PIX</td>
              <td data-label="Forma de pagamento" v-if="item.type_payment == 3">
                Cartão de Crédito
              </td>
              <td data-label="Forma de pagamento" v-if="item.type_payment == 4">
                Cartão de Débito
              </td>
              <td data-label="Valor">R$ {{ item.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- {{ formData.entrance_and_exit }} -->
</template>

<script>
import '@/assets/table.css';
import { useToast } from 'vue-toastification';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import router from '@/router';

const toast = useToast();

export default {
  name: 'SaleShow',

  components: {
    ButtonSubmit
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.loadBoxes();
  },

  data() {
    return {
      formData: {},
      paymentsTotal: ''
    };
  },

  methods: {
    loadBoxes() {
      this.$store
        .dispatch('loadHistoricBoxes', this.id)
        .then(r => {
          this.formData = r;
          this.formData.id = this.id;
          this.verifyCashier();
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1200 });
        });
    },

    openPrintClose(id) {
      let url = router.resolve({ name: 'box-print-close', params: { id: id } });
      window.open(url.fullPath);
    },

    verifyCashier() {
      this.$store.dispatch('getBox');
    }
  }
};
</script>

<style scoped>
.main-card {
  margin: 5px 0 0 5px;
  max-height: 90vh; /* ou qualquer valor que faça sentido para o seu layout */
  overflow: auto;
}

.spacing-border {
  border: 1px solid #000;
}

table {
  font-size: 1.02rem;
}

table thead tr > th,
.tableDark {
  background-color: #000;
  color: #fff;
  font-weight: 600;
  border: none;
}

tbody tr {
  border-color: #eeeeee;
}

tbody tr td,
tbody tr th {
  background-color: #fcfcfc;
  font-weight: 300;
}

.table {
  margin-bottom: 0;
}
</style>
