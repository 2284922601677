<template>
  <div
    class="modal-w d-grid position-fixed h-100 w-100 start-0 top-0"
    v-if="openModalSearchClient || openModalSearchProduct"
  >
    <client-search-modal
      v-if="openModalSearchClient"
      @close="closeModalClientSerach"
    ></client-search-modal>
    <product-search
      v-if="openModalSearchProduct"
      @close="closeModalClientProduct"
      :product-serach="formDataProduct"
    ></product-search>
  </div>

  <form>
    <row-component>
      <col-component class-col="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
        <label>Cliente:</label>
        <div class="input-group mb-3">
          <input-component disabled :model-value="formData.client_name" />
          <span
            class="input-group-text cursorPointer"
            id="basic-addon1"
            @click="openModalSearchClient = true"
          >
            <i class="search icon"></i>
          </span>
        </div>
      </col-component>

      <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4">
        <button-submit @click="openModalSearchProduct = true" icon="plus" name="Produto" />
      </col-component>

      <!-- <col-component class-col="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
        <label>Consultora</label>
        <MultiSelect />
      </col-component> -->
    </row-component>
  </form>

  <div class="bg-body-secondaryd">
    <div class="bg-white">
      <div class="__table">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" style="width: 5%">Item</th>
              <th scope="col" style="width: 10%">Referência</th>
              <th scope="col" style="width: 10%">Foto</th>
              <th scope="col" style="width: 35%">Nome</th>
              <th scope="col" style="width: 10%">Valor UN</th>
              <th scope="col" style="width: 10%">UN</th>
              <th scope="col" style="width: 10%">Valor Total</th>
              <th scope="col" style="width: 10%">Remover</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(data, index) in products" :key="index">
              <td scope="col" data-label="Item">{{ index + 1 }}</td>
              <td scope="col" data-label="Referência">{{ data.ref }}</td>

              <td data-label="Foto">
                <img class="rounded-circle" width="50" :src="data.photo" alt="" />
              </td>
              <td data-label="Nome">{{ data.name_product }}</td>
              <td data-label="Valor UN">R$ {{ data.price_un }}</td>
              <td data-label="UN">{{ data.amount }}</td>
              <td data-label="Valor Total">R$ {{ data.price_total }}</td>
              <td data-label="Remover" @click="destroyProduct(data)">
                <button-delete />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.scrolAuto {
  overflow-y: scroll;
  height: calc(100vh - 340px);
}
</style>
<script>
import '@/assets/table.css';
import ColComponent from '@/components/row/ColComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import RowComponent from '@/components/row/RowComponent.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import ButtonDelete from '@/components/buttons/ButtonDelete.vue';
import ClientSearchModal from '@/components/modals/ClientSearchModal.vue';
import ProductSearch from '@/components/modals/ProductSearch.vue';
import { useToast } from 'vue-toastification';
import swal from 'sweetalert';

const toast = useToast();

export default {
  name: 'order-products',
  components: {
    ProductSearch,
    ClientSearchModal,
    ButtonDelete,
    ButtonSubmit,
    RowComponent,
    InputComponent,
    ColComponent
  },

  props: {
    dataClient: {
      required: true
    },

    idSale: {
      required: true
    }
  },

  mounted() {
    window.addEventListener('keydown', this.OnkeyDown);
  },

  data() {
    return {
      openModalSearchClient: false,
      openModalSearchProduct: false,
      formData: {
        id: '',
        client_name: ''
      },

      formDataProduct: {
        barcode: '',
        barcodeReader: ''
      }
    };
  },

  methods: {
    OnkeyDown(e) {
      if (e.key == 'Enter') {
        if (this.formDataProduct.barcodeReader) this.setCodeBar(this.formDataProduct.barcodeReader);
        this.setTimeoutCodeBar();
        return;
      }

      if (e.key != 'Shift') this.formDataProduct.barcodeReader += e.key;

      this.setTimeoutCodeBar();
    },

    setTimeoutCodeBar() {
      setTimeout(() => {
        this.formDataProduct.barcodeReader = '';
        this.formDataProduct.barcode = '';
      }, 1000);
    },

    setCodeBar(code) {
      this.formDataProduct.barcode = code;
      this.openModalSearchProduct = true;
    },

    updateFormData() {
      this.formData = this.dataClient;
    },

    closeModalClientSerach(data) {
      this.openModalSearchClient = false;
      this.updateClientSale(data);
    },

    closeModalClientProduct() {
      this.openModalSearchProduct = false;
    },

    updateClientSale(formDate) {
      if (formDate != null) {
        let fomrDateSend = {
          client_id: formDate.id,
          idSale: this.idSale
        };

        this.$store
          .dispatch('salesUpdateClient', fomrDateSend)
          .then(r => {
            this.formData.client_name = r.name_client;
          })
          .catch(() => {
            toast.error('Erro desconhecido!', { timeout: 1200 });
          });
      }
    },

    destroyProduct(data) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente remover o produto ${data.name_product} referência ${data.ref}  ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store.dispatch('destroyProduct', data.id);
          toast.success('Produto removido com sucesso!', { timeout: 2000 });
        }
      });
    }
  },

  computed: {
    products() {
      return this.$store.state.order.items.product;
    }
  },

  beforeMount() {
    setTimeout(() => this.updateFormData(), 1000);
  }
};
</script>
