import axios from "axios";
import {URL_BASE_PDV} from "@/configs/constants";


export default {

    openCashier(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_PDV}open-box`, formData)
                .then((response) => {
                    context.commit("BOX", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    getBox(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_PDV}get-box`, formData)
                .then((response) => {
                    context.commit("BOX", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    closeBox(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}close-box`)
                .then((response) => {
                    context.commit("BOX", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    storeSangria(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_PDV}add-remove-sangria-box`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    getBoxResume(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_PDV}get-box-resume/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    }




}