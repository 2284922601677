import AdminComponent from "@/components/AdminComponent.vue";
import HistoricBoxShow from "@/views/show/HistoricBoxShow.vue";
import HistoricShow from "@/views/show/HistoricShow.vue";
import SaleShow from "@/views/show/SaleShow.vue";

export default {
    path: '/',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'historic-show', name: 'historic-show', component: HistoricShow},
        {path: 'historic-show-sale/:id', name: 'historic-show-sale', component: SaleShow, props:true},
        {path: 'historic-show-boxes/:id', name: 'historic-show-boxes', component: HistoricBoxShow, props:true},
    ]
}