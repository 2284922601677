<template>
  <div class="shadow-lg w-100 d-flex align-items-center justify-content-between headerTitle">
    <p class="mb-0 fw-bold text-uppercase">{{ this.statusOrder() }}</p>
    <div class="btn-group" role="group" aria-label="Basic example">
      <!-- @click="openOrder" -->
      <button-submit
        :icon="'list'"
        class="float-end buttonHeader"
        @click="modalConsultant"
        v-if="orderStore == 4"
        name="Novo pedido"
      />
      <button-submit
        :icon="'reply all '"
        @click="returnSale"
        v-if="orderStore == 2"
        class="float-end buttonHeader"
        name="Voltar"
      />
      <button-submit
        :icon="'edit '"
        class="float-end buttonHeader"
        v-if="orderStore == 1 || orderStore == 2"
        @click="modalUpdateConsultant"
        name="Consultora"
      />
      <button-submit
        :icon="'close'"
        @click="canceledSale"
        v-if="orderStore == 1 || orderStore == 2"
        class="float-end buttonHeader"
        name="Cancelar"
      />
      <button-submit
        :icon="'money bill alternate outline'"
        @click="paymentPut"
        v-if="orderStore == 1"
        class="float-end buttonHeader"
        name="Pagamento"
      />
      <button-submit
        :icon="'check circle outline'"
        @click="endSale"
        v-if="orderStore == 2"
        class="float-end buttonHeader"
        name="Finalizar"
      />
    </div>
  </div>

  <!-- MODAL CONTULTORA -->
  <div class="modal-window" v-if="openModalContultante">
    <div class="container-card camp-schedule">
      <div role="button" class="btn-close" data-v-3c7c0b1c="" @click="modalConsultant"></div>
      <h2>Informar a consultora</h2>
      <form>
        <MultiSelect v-model="consultant_id" :options="consultants" label="name" track-by="value" />

        <button-submit
          @click="openOrder"
          class="buttonHeader mt-2"
          v-if="orderStore == 4"
          name="Novo Pedido"
        />
      </form>
    </div>
  </div>

  <div class="modal-window" v-if="openModalUpdateContultante">
    <div class="container-card camp-schedule">
      <div role="button" class="btn-close" @click="modalUpdateConsultant"></div>
      <h2>Alterar consultora</h2>
      <form>
        <MultiSelect v-model="consultant_id" :options="consultants" label="name" track-by="id" />

        <button-submit @click="updateConsultant" class="buttonHeader mt-2" name="Alterar" />
      </form>
    </div>
  </div>

  <div class="painel">
    <order-products
      :data-client="formData"
      :id-sale="formData.id"
      v-if="orderStore == 1"
    ></order-products>
    <order-payment id-sale="formData.id" v-if="orderStore == 2"></order-payment>
  </div>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import OrderProducts from '@/views/create/order-products.vue';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';
import OrderPayment from '@/views/create/order-payment.vue';
import router from '@/router';
import MultiSelect from '@/components/form/MultiSelect.vue';

const toast = useToast();

export default {
  name: 'OrderSearch',
  components: {
    OrderPayment,
    OrderProducts,
    ButtonSubmit,
    MultiSelect
  },

  created() {
    this.getOrder();
    this.loadUsers();
  },

  data() {
    return {
      formData: {
        client_id: '',
        name_client: '',
        order_id: ''
      },

      formDataOrder: {
        sale: 3,
        code: ''
      },

      openModalContultante: false,
      openModalUpdateContultante: false,
      consultant_id: '',
      consultants: []
    };
  },

  methods: {
    verifyCashier() {
      this.$store.dispatch('getBox');
    },

    loadUsers() {
      this.$store
        .dispatch('loadUsers')
        .then(r => {
          this.consultants = r;
          this.setConsultant();
        })
        .catch(() => {});
    },

    setConsultant() {
      if (this.formData && this.formData.consultant_id) {
        this.consultant_id = this.formData.consultant_id;
      }
    },

    getOrder() {
      this.$store
        .dispatch('getOrder')
        .then(r => {
          this.formDataOrder = r;
          this.formData = r;

          this.verifyCashier();
        })
        .catch(() => {
          toast.error('Erro desconhecido!', { timeout: 1200 });
        });
    },

    openOrder() {
      const payload = {
        consultant_id: this.consultant_id
      };
      this.$store
        .dispatch('openOrder', payload)
        .then(r => {
          this.formDataOrder = r;
          this.openModalContultante = !this.openModalContultante;
          toast.success('Pedido aberto com sucesso', { timeout: 1500 });
        })
        .catch(() => {});
    },

    statusOrder() {
      if (this.formDataOrder.sale == 1) return 'Pedido Aberto Nº ' + this.formDataOrder.code;

      if (this.formDataOrder.sale == 2) return 'Pedido em pagamento Nº' + this.formDataOrder.code;

      if (this.formDataOrder.sale == 4) return 'Nehum Pedido aberto';
    },

    canceledSale() {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente cancelar o pedido ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('canceledOrder')
            .then(r => {
              this.formDateProduct = r;
              toast.warning(`Pedido Cancelado com sucesso`, { timeout: 6000 });
            })
            .catch();
        }
      });
    },

    paymentPut() {
      this.$store
        .dispatch('paymentPut')
        .then(r => {
          this.formDataOrder.sale = r.sale;
        })
        .catch();
    },

    returnSale() {
      this.$store
        .dispatch('returnSale')
        .then(r => {
          this.formDataOrder.sale = r.sale;
        })
        .catch();
    },

    endSale() {
      this.$store
        .dispatch('closeSale')
        .then(() => {
          this.openPrintClose();
          toast.warning(`Pedido finalizado com sucesso`, { timeout: 6000 });
        })
        .catch(e => {
          let msg = e.response.data.error;
          swal({
            title: 'Atenção!',
            text: `${msg}, ${this.me.name}!`,
            closeOnClickOutside: false
          });
        });
    },

    openPrintClose() {
      let url = router.resolve({ name: 'sale-print-close', params: { id: this.formData.id } });
      window.open(url.fullPath);
    },

    modalConsultant() {
      this.consultant_id = this.me.id;
      this.openModalContultante = !this.openModalContultante;
    },

    modalUpdateConsultant() {
      this.setConsultant();
      this.openModalUpdateContultante = !this.openModalUpdateContultante;
    },

    updateConsultant() {
      const payload = {
        consultant_id: this.consultant_id,
        id: this.formData.id
      };

      this.$store
        .dispatch('updateConsultant', payload)
        .then(() => {
          this.getOrder();
          this.loadUsers();
          this.openModalUpdateContultante = false;
          toast.success('Consultora alterado com sucesso', { timeout: 1500 });
        })
        .catch(() => {});
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    orderStore() {
      return this.$store.state.order.items.sale;
    }
  }
};
</script>

<style scoped>
.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 60%;
  overflow-y: auto;
  min-height: 550px;
  background: #ffffff;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.painel {
  padding: 1rem;
  background: #ffffff;
}

.modal-window {
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  place-items: center;
  background-color: rgb(0, 0, 0, 0.7);
}

.modal-window > .camp-share,
.camp-schedule {
  width: 50%;
  background-color: #fff;
}

.container-card {
  position: relative;
  padding: 2%;
  margin: 3% 2% 5% 2%;
  background-color: #fff;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
}

.container-card .painelContent {
  clear: both;
}

.container-card h2 {
  margin-bottom: 2%;
  font-size: 1.5rem;
  color: #353535;
  position: relative;
  left: 10px;
  text-align: center;
}

@media (max-width: 991px) {
  .headerTitle {
    flex-direction: column;
    height: auto;
    padding: 10px;
    grid-gap: 10px 0;
  }
}

@media (max-width: 720px) {
  .modal-window > .camp-share,
  .camp-schedule {
    width: 90%;
    background-color: #fff;
  }
}

.btn-group {
  float: right;
  padding: 6px 0 6px 10px;
}
</style>
