import axios from 'axios';
import { URL_BASE_PDV } from '@/configs/constants';

export default {
  loadHistoric(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE_PDV}historic`, { params })
        .then(response => {
          context.commit('LOAD_HISTORIC', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  loadHistoricSales(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE_PDV}historic-sales`, { params })
        .then(response => {
          context.commit('LOAD_HISTORIC_SALE', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  loadHistoricSale(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE_PDV}historic-sales-show/${id}`)
        .then(response => {
          context.commit('LOAD_HISTORIC_SALE', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  loadHistoricBox(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE_PDV}historic-boxes`, { params })
        .then(response => {
          context.commit('LOAD_HISTORIC', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  loadHistoricBoxes(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE_PDV}historic-box-show/${id}`)
        .then(response => {
          context.commit('LOAD_HISTORIC_SALE', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  }
};
