<template>
    <Multiselect :noResultsText="noResultsText" :noOptionsText="noOptionsText" :searchable="true" :options="options"/>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
    name: "MultiSelect",
    components: {
        Multiselect
    },

    props: {
        options: {
            required: true
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },
    }
}
</script>