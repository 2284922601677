import axios from 'axios';
import { URL_BASE_PDV } from '@/configs/constants';
import { URL_BASE } from '@/configs/constants';

export default {
  searchCNPJ(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE_PDV}client-check-exist`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(() => {
          errors => reject(errors);
        });
    });
  },

  searchApiCNPJ(context, formData) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          Authorization: '819cee50-84dc-4499-b7c9-8204c26014ac-dc3db308-a66d-44f8-b997-079d5b7f7c80'
        }
      };
      axios
        .get(`https://api.cnpja.com/office/${formData.cnpj}`, config)
        .then(r => {
          resolve(r);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  searchCPF(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE_PDV}client-check-exist`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  searchApiCEP(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`https://viacep.com.br/ws/${formData.cep}/json/`)
        .then(r => {
          resolve(r);
        })
        .catch(errors => {
          reject(errors);
          context.commit('LOADER', false);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  storeClient(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE_PDV}client`, formData)
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        })
        .finally(() => context.commit('LOADER', false));
    });
  },

  loadClients(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}client-list`)
        .then(response => {
          context.commit('LOAD_CLIENTS', response.data);
        })
        .catch(e => reject(e));
    });
  }

  //   loadUsers(context) {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(`${URL_BASE}user-list`)
  //         .then(response => {
  //           context.commit('LOAD_CLIENTS', response.data);
  //         })
  //         .catch(e => reject(e));
  //     });
  //   }
};
