import { createApp } from 'vue'
import App from './App.vue'
import axios from "axios";
import router from './router'
import store from './store'
import { VMoney } from "v-money";  // Adicione esta linha

//Main CSS
import "@/assets/main.css"


// Icons default
import "@/assets/icons/icon.min.css"

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

//MultiSelect
import "@vueform/multiselect/themes/default.css"

//Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"

import {NAME_TOKEN} from "@/configs/constants";

const tokenAccess = localStorage.getItem(NAME_TOKEN)
if (tokenAccess) axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAccess}`;

if (process.env.NODE_ENV === "production") axios.defaults.baseURL = "https://system.regallisemijoias.com.br/"
else axios.defaults.baseURL = "https://regallisemijoias.d7mmarketing.com.br/"

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

createApp(App)
    .use(store)
    .use(router)
    .use(Toast)
    .directive('money', VMoney)
    .mount('#app')

store.dispatch("loginCheck")
    .catch(() => store.dispatch("logout"))
