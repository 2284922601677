<template>
  <div class="painel p-4 pt-5" style="width: 500px">
    <error-component v-if="showError" :errors="errors"/>

    <div role="button" @click="[clear, this.$emit('close')]" class="btn-close"></div>
    <form>
      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                       :class="{'formError' : errors.entrance}">
          <label>Dar entrada caixa:</label>
          <MultiSelect v-model="formData.entrance" :options="selectYesOrNo" @select="onChangeEntrance"/>
        </col-component>

        <div v-if="showEntrance">
          <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                         :class="{'formError' : errors.price}">
            <label>Valor:</label>
            <input-component v-money="MONEY" v-model="formData.price"/>
          </col-component>

          <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                         :class="{'formError' : errors.reason}">
            <label>Motivo:</label>
            <input-component v-model="formData.reason"/>
          </col-component>

        </div>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" name="Abrir Caixa"/>
        </col-component>
      </row-component>
    </form>
  </div>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import MultiSelect from "@/components/form/MultiSelect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
  name: "OpenBoxModal",
  components: {
    ErrorComponent,
    ButtonSubmit,
    InputComponent,
    MultiSelect,
    ColComponent,
    RowComponent
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      formData: {
        entrance: 1,
        price: "",
        reason: ""
      },

      MONEY,

      errors: [],
      sendForm: false,
      showError: false,
      showEntrance: false
    }
  },

  methods: {
    submit() {
      if (!this.sendForm) {
        this.sendForm = true
        this.$store.dispatch('openCashier', this.formData)
            .then((r) => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              this.$emit("close",r)
              toast.success('Caixa aberto com sucesso', {timeout: 1500})
            }).catch((e) => {
          this.showError = true
          this.sendForm = false
          this.errors = e.response.data.errors
        })
      }
    },

    onChangeEntrance(item) {
      if (item === 2)
        this.showEntrance = true

      if (item === 1)
        this.showEntrance = false

    },


    clear() {
      this.formData = {
        entrance: 2,
        price: "",
        reason: ""
      }
    }
  },

  computed: {
    selectYesOrNo() {
      return this.$store.state.var.selectYesOrNo
    }
  }
}
</script>

<style scoped>


.painel {
  position: relative;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}


</style>