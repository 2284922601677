export default {
    noResultsText: "Nada encontrado",
    noOptionsText: "Esta lista está vazia",

    selectActiveInative: [
        {value: 1, label: "Ativo"},
        {value: 2, label: "Inativo"},
    ],

    selectStatusHistoric: [
        {value: 1, label: "Ativo"},
        {value: 2, label: "Em Pagamento"},
        {value: 3, label: "Cancelado"},
        {value: 4, label: "Finalizado"},
    ],

    selectYesOrNo: [
        {value: 1, label: "Não"},
        {value: 2, label: "Sim"},
    ],

    selectEntranceExit: [
        {value: 1, label: "Entrada"},
        {value: 2, label: "Saída"},
    ],

    selectPaymentType: [
        {value: 1, label: "Dinheiro"},
        {value: 2, label: "Pix"},
        {value: 3, label: "Cartão de crédito"},
        {value: 4, label: "Cartão de débito"},
    ],

    selectNotSearch: 'Nada foi encontrado com os parâmetros informados!',
    selecNullSearch: 'Carregando...',


}

let estados = [];
estados['AC'] = 'Acre';
estados['AL'] = 'Alagoas';
estados['AP'] = 'Amapá';
estados['AM'] = 'Amazonas';
estados['BA'] = 'BahiaBahia';
estados['CE'] = 'Ceará';
estados['DF'] = 'Distrito Federal';
estados['ES'] = 'Espírito Santo';
estados['GO'] = 'Goiás';
estados['MA'] = 'Maranhão';
estados['MT'] = 'Mato Grosso';
estados['MS'] = 'Mato Grosso do Sul';
estados['MG'] = 'Minas Gerais';
estados['PA'] = 'Pará';
estados['PB'] = 'Paraíba';
estados['PR'] = 'Paraná';
estados['PE'] = 'Pernambuco';
estados['PI'] = 'Piauí';
estados['RJ'] = 'Rio de Janeiro';
estados['RN'] = 'Rio Grande do Norte';
estados['RS'] = 'Rio Grande do Sul';
estados['RO'] = 'Rondônia';
estados['RR'] = 'Roraima';
estados['SC'] = 'Santa Catarina';
estados['SP'] = 'São Paulo';
estados['SE'] = 'Sergipe';
estados['TO'] = 'Tocantins';

export let ESTADOS = estados