import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

export default {
  loadUsers(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}user-list`)
        .then(response => {
          context.commit('LOADER_USERS', response.data);
          return resolve(response.data);
        })
        .catch(e => reject(e));
    });
  }
};
