export default {

    LOAD_ORDER_CLIENT(state, clients) {
        state.clients = clients
    },

    ORDER(state, items) {
        state.items = items
    },

    ORDERBOX(state, items) {
        state.items.boxTotal = items
    },

    ORDERPRODUCT(state, items) {
        state.items.product = items
    },

    ORDERPAYMENT(state, items) {
        state.items.payment = items
    }

}