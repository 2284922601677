import {createStore} from 'vuex'

import Auth from "@/store/auth";
import Loader from "@/store/loader";
import Var from "@/store/var";
import Box from "@/store/box";
import Order from "@/store/order";
import Historic from "@/store/historic";
import Client from "@/store/client";
import User from "@/store/user";

export default createStore({
    modules: {
        auth: Auth,
        loader: Loader,
        var: Var,
        box: Box,
        order: Order,
        historic: Historic,
        client: Client,
        user: User

    }
})
