import AdminComponent from "@/components/AdminComponent.vue";
import OrderSearch from "@/views/show/OrderShow.vue";

export default {
    path: '/',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'order-show', name: 'order-show', component: OrderSearch},
    ]
}