<template>
  <div class="body">
    <div class="login-page">
      <div class="imgLogin">
        <img src="@/assets/logo-white.png" alt="" />
      </div>

      <div class="form">
        <form class="login-form" @submit.prevent="login">
          <input v-model="formData.email" placeholder="Login" maxlength="20" type="text" />
          <input v-model="formData.password" placeholder="Senha" maxlength="10" type="password" />
          <button
            :disabled="sendForm"
            :class="{ 'preloader-send': sendForm }"
            type="submit"
            @submit="login"
          >
            Entrar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'LoginView',

  data() {
    return {
      formData: {
        email: '',
        password: ''
      },

      sendForm: false
    };
  },

  methods: {
    login() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('login', this.formData)
          .then(() => {
            toast.success('Login efetuado com sucesso!', { timeout: 2000 });
            this.$router.push({ name: 'box-show' });
            this.sendForm = false;
          })
          .catch(() => {
            toast.error('E-mail ou senha inválida', { timeout: 2000 });
            this.sendForm = false;
          });
      }
    }
  }
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.body {
  width: 100vw;
  height: 100vh;
  background-color: #2d2d2d !important;
}

.login-page {
  margin: auto;
  width: 450px;
  padding: 8% 0 0;
}

.form {
  z-index: 1;
  padding: 45px;
  position: relative;
  text-align: center;
  background: #ffffff;
  margin: 0 auto 100px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form p {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  text-align: left;
  padding: 0 0 0 5%;
}

.form input {
  font-family: 'Roboto', sans-serif;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  margin: 0 0 15px;
  background: #f2f2f2;
  box-sizing: border-box;
}

.form button {
  font-family: 'Roboto', sans-serif;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  background: #000000;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: #000000;
}

.login-page .imgLogin img {
  display: block;
  margin-left: auto;
  max-height: 100px;
  margin-right: auto;
}

.login-page .imgLogin {
  padding: 5% 0;
  background-color: #000000;
}

@media (max-width: 767px) {
  .login-page {
    width: 96%;
  }

  .login-page img {
    max-width: 200px;
  }

  .form {
    padding: 20px;
  }
}
</style>
