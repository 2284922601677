<template>
  <table class="printer-ticket">
    <thead>
    <tr>
      <th class="title" colspan="3">Regalli Semijias <br>CNPJ:35.306.627/0001-50</th>
    </tr>

    <tr>
      <th colspan="3">{{ dateHour }}</th>
    </tr>
    <tr>
      <th colspan="3">
        Funcionário: {{formData.nameUser}} <br/>

        Aberto: {{formData.openDateHour}} <br>
        Fechado: {{formData.closeDateHour}}
      </th>
    </tr>

    <tr>
      <th class="ttu" colspan="3">
        <b>Resumo Caixa Nº {{formData.code}}</b>
      </th>
    </tr>

    </thead>

    
      
    
    <tbody>
      <tr class="sup ttu p--0">
      <td colspan="3">
        <b>Totais</b>
      </td>
    </tr>

    <tr class="ttu">
      <td colspan="2">Dinheiro</td>
      <td align="right">R$ {{formData.balanceMoney}}</td>
    </tr>

    <tr class="ttu">
      <td colspan="2">Pix</td>
      <td align="right">R$ {{formData.balancePix}}</td>
    </tr>
    <tr class="ttu">
      <td colspan="2">CARTÃO DE CRÉDITO</td>
      <td align="right">R$ {{formData.balanceCredit}}</td>
    </tr>
    <tr class="ttu">
      <td colspan="2">Cartão de débito</td>
      <td align="right">R$ {{formData.balanceDebit}}</td>
    </tr>

    <tr class="ttu boldText">
      <td colspan="2" >Total</td>
      <td align="right">R$ {{formData.total}}</td>
    </tr>

    <tr class="reasons"></tr>

    <tr>
      <th class="ttu" colspan="3">
        <b>TOTAIS VENDAS (SANGRIA)</b>
      </th>
    </tr>

    <tr class="ttu">
      <td colspan="2">Total caixa</td>
      <td align="right">
        R$ {{formData.total}}
      </td>
    </tr>

    <tr class="ttu">
      <td colspan="2">Total caixa (Sangria)</td>
      <td align="right">
        -R$ {{formData.totalBoxSangria}}
      </td>
    </tr>

    <tr class="ttu">
      <td colspan="2">Total Saída</td>
      <td align="right">
        -R$ {{formData.totalExit}}
      </td>
    </tr>
    
    <tr class="ttu boldText">
      <td colspan="2">Total Vendas</td>
      <td align="right">
        R$ {{formData.totalSales}}
      </td>
    </tr>

    <tr class="reasons"></tr>

    <tr>
      <th class="ttu" colspan="3">
        <b>Entrada/Saída Dinheiro</b>
      </th>
    </tr>


    
    <tr class="sup ttu p--0">
      <td colspan="3">
        <b>Motivo</b>
      </td>
    </tr>
    <tr class="ttu" v-for="(item, index) in formData.entranceAndExit" :key="index">
        <td colspan="2">{{item.reason}} </td>
        <td align="right">
          {{ item.type == 2 && item.price !== '0,00' ? '- ' : '' }}R$ {{ item.price }}
        </td>
      </tr>


      
      
    </tbody>
    
    <tfoot>
      <tr class="sup boldText">
        <td colspan="3" align="center">SUBTOTAL: R$ {{formData.subtotalEntranceAndExit}}</td>
      </tr>
    
      <tr class="sup">
        <td colspan="3" align="center">
          www.regallisemijoias.com.br
        </td>
      </tr>
   
    </tfoot>

    

  </table>
</template>

<style scoped>

</style>
<script>
import router from "@/router";
import "@/assets/cupom.css"
import toast from "bootstrap/js/src/toast";

export default {
  name: 'BoxPrint',


  created() {
    this.formData.id = router.currentRoute.value.params.id
    this.dateHour = this.getDateHours()
    this.loadPrint()
  },


  data() {
    return {
      formData:{
        id: '',
        balanceMoney:'',
        balancePix:'',
        balanceCredit:'',
        balanceDebit:'',
        openDateHour: '',
        closeDateHour:'',
        code:'',
        nameUser:'',
        total:''
      },

      dateHour: ''

    }
  },

  methods: {


    loadPrint(){
      this.$store.dispatch("getBoxResume",this.formData.id)
          .then((r) => {
            this.formData = r

          }).catch(() => {
        toast.error("Erro desconhecido!", {timeout: 1200})
      }).finally(() => window.print())


    },


    getDateHours() {
      let d = new Date();
      let dia = d.getDate().toString().padStart(2, '0')
      let mes = (d.getMonth() + 1).toString().padStart(2, '0')
      let ano = d.getFullYear();
      let h = d.getHours()
      let m = d.getMinutes()
      let s = d.getSeconds()

      if (h <= 9)
        h = "0" + h;

      if (m <= 9)
        m = "0" + m

      if (s <= 9)
        s = "0" + s

      return dia + "/" + mes + "/" + ano + " - " + h + ":" + m + ":" + s;

    },

  }
}
</script>
