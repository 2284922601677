<template>
    <router-link :to="{name:route, params:{id: id}}" class="btn btn-secondary rounded-0 me-1">
        <i class="m-0 eye icon"></i>
    </router-link>
</template>

<script>
export default {
    name: "ButtonShow",

    props: {
        route: {
            'type': String,
            "required": true,
        },

        id: {
            'type': Number,
            "required": true,
        }
    },
}
</script>
