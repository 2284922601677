<template>
    <div class="painel p-4 pt-5">
        <error-component v-if="showError" :errors="errors"/>

        <div role="button" @click="[clear, this.$emit('close')]" class="btn-close"></div>
        <form>
            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.type}">
                    <label>Tipo:</label>
                    <MultiSelect v-model="formData.type" :options="selectEntranceExit"/>
                </col-component>

                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.price}">
                    <label>Valor:</label>
                    <input-component v-money="MONEY" v-model="formData.price"/>
                </col-component>

                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.reason}">
                    <label>Motivo:</label>
                    <input-component v-model="formData.reason"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="changeButtonLabel" @click="submit"  :name="changeButtonLabel"/>
                </col-component>
            </row-component>
        </form>
    </div>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import MultiSelect from "@/components/form/MultiSelect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "SangriaModal",
    components: {
        ErrorComponent,
        ButtonSubmit,
        InputComponent,
        MultiSelect,
        ColComponent,
        RowComponent
    },

    directives: {
        money: VMoney
    },

    data() {
        return {
            MONEY,

            formData: {
                type: null,
                price: "",
                reason: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    //Opção padrão no multselect
    created() {
        if (this.selectEntranceExit.length > 0) {
            this.formData.type_payment = this.selectEntranceExit[0].value;
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeSangria', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.$emit('close', r)
                        toast.success('Sangria realizada com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        clear() {
            this.formData = {
                typet: null,
                price: "",
                reason: ""
            }
        },
    },

    computed: {
        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit;
        },

        changeButtonLabel() {
            // Encontra a opção selecionada no selectEntranceExit e armazena na const selectedOption
            const selectedOption = this.selectEntranceExit.find(option => option.value === this.formData.type);
            // Verifica se selectionOption for diferente de selectedOption.label então retorna 'Remover'
            // Se não retorna a opção selectionada no multselect
            return selectedOption ? `${selectedOption.label}` : '';
        }
    }
}
</script>

<style scoped>
.painel {
    position: relative;
    background: #FFFFFF;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}
</style>