export const URL_BASE = '/api/v1/admin/'
export const URL_BASE_PDV = '/api/v1/admin/pdv/'
export const NAME_TOKEN = 'TOKEN_AUTH'

const money = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false /* doesn't work with directive */
}

export const MONEY = money