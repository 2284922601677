<template>
  <div class="shadow-lg w-100 d-flex align-items-center justify-content-between headerTitle">
    <p class="mb-0 fw-bold text-uppercase">Cliente</p>
  </div>

  <div class="painel">
    <error-component :errors="errors" v-if="showError" class="errorcomponet"/>
    <section class="form">
      <form>
        <row-component>
          <col-component
              :classCol="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
            <input @change="checkType" v-model="formData.type" type="radio" name="docs"
                   id="pessoa-fisica" value="1">
            <label for="pessoa-fisica">Pessoa Física</label>

            <input @change="checkType" v-model="formData.type" type="radio" name="docs"
                   id="pessoa-juridica" value="2">
            <label for="pessoa-juridica">Pessoa Jurídica</label>
          </col-component>
        </row-component>

        <div v-if="picked === 2">
          <row-component>
            <col-component
                :classCol="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.cnpj}">


              <label for="CNPJ">CNPJ:</label>
              <input-component  v-model:model-value="formData.cnpj" v-mask="'##.###.###/####-##'" @change="searchCNPJ"/>

            </col-component>

            <col-component
                :classCol="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.size}">
              <label>Porte:</label>
              <input-component :type-input="'text'" v-model="formData.size"/>
            </col-component>

            <col-component
                :classCol="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.status}">
              <label>Status:</label>
              <Multiselect v-model="formData.status" :options="selectActiveInative"
                           :searchable="true"
                           :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
            </col-component>
          </row-component>

          <row-component>
            <col-component
                :classCol="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.name}">
              <label>Nome:</label>
              <input-component :type-input="'text'" v-model="formData.name" @keyup="surnameOnkeypress"/>
            </col-component>

            <col-component
                :classCol="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.phone}">
              <label>Telefone:</label>
              <input-component :type-input="'text'" v-model="formData.phone" v-mask="['(##) ####-####', '(##) #####-####']" />
            </col-component>

            <col-component
                :classCol="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.email}">
              <label>E-mail:</label>
              <input-component :type-input="'text'" v-model="formData.email"  />
            </col-component>


          </row-component>


        </div>




        <div v-else>
          <row-component>
            <col-component
                :classCol="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.name}">
              <label>Nome:</label>
              <input-component :type-input="'text'" v-model="formData.name"  @keyup="surnameOnkeypress"/>
            </col-component>

            <col-component
                :classCol="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.phone}">
              <label>Telefone:</label>
              <input-component :type-input="'text'" v-model="formData.phone" v-mask="['(##) ####-####', '(##) #####-####']" />
            </col-component>

            <col-component
                :classCol="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.email}">
              <label>E-mail:</label>
              <input-component :type-input="'text'" v-model="formData.email"  />
            </col-component>


          </row-component>


          <row-component>
            <col-component
                :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.cpf}">
              <label>CPF:</label>
              <input-component v-mask="'###.###.###-##'" @change="searchCPF" :type-input="'text'"
                               v-model="formData.cpf"/>
            </col-component>

            <col-component
                :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.rg}">
              <label>RG:</label>
              <input-component v-mask="['##.###.###-X', '#.###.###']" :type-input="'text'"
                               v-model="formData.rg"/>
            </col-component>

            <col-component
                :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.birth_date}">
              <label>Data Nasc:</label>
              <input-component v-mask="'##/##/####'" :type-input="'text'"
                               v-model="formData.birth_date"/>
            </col-component>

            <col-component
                :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                :class="{'formError' : errors.status}">
              <label>Status:</label>
              <Multiselect v-model="formData.status" :options="selectActiveInative"
                           :searchable="true"
                           :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
            </col-component>
          </row-component>
        </div>

        <row-component>
          <col-component
              :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.cep}">
            <label>CEP:</label>
            <input-component v-mask="'#####-###'"  :type-input="'text'" @change="searchApiCEP"
                             v-model="formData.cep"/>
          </col-component>

          <col-component
              :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.city}">
            <label>Cidade:</label>
            <input-component :type-input="'text'" v-model="formData.city"/>
          </col-component>

          <col-component
              :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.state}">
            <label>Estado:</label>
            <input-component :type-input="'text'" v-model="formData.state"/>
          </col-component>

          <col-component
              :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.district}">
            <label>Bairro:</label>
            <input-component :type-input="'text'" v-model="formData.district"/>
          </col-component>
        </row-component>

        <row-component>
          <col-component
              :classCol="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.address}">
            <label>Endereço:</label>
            <input-component :type-input="'text'" v-model="formData.address"/>
          </col-component>

          <col-component
              :classCol="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.country}">
            <label>País:</label>
            <input-component :type-input="'text'" v-model="formData.country"/>
          </col-component>
        </row-component>

        <row-component>
          <col-component
              :classCol="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errors.complement}">
            <label>Referência:</label>
            <text-area :rows="4" v-model="formData.complement"/>
          </col-component>
        </row-component>
      </form>

      <button-submit :icon="'plus'"  class="float-end buttonHeader "
                     name="Adicionar" @click="addClient"/>

    </section>
  </div>
</template>

<style scoped>

.painel{
  overflow-y: auto;
  height: 85vh;
}

.form{
  width: 95%;
  margin-top: 2%;
  margin-left: 2%;
}
.errorcomponet{
  width: 95%;
  margin-left: 2%;
  margin-top: 10px;
}
</style>
<script>


import RowComponent from "@/components/row/RowComponent.vue";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea.vue";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import {ESTADOS} from "@/store/var/state";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
const toast = useToast()

export default {
  name: "client-create",
  components: {
    ButtonSubmit,
    TextArea,
    InputComponent,
    ColComponent,
    ErrorComponent,
    RowComponent,
    Multiselect
  },

  directives: {
    mask
  },

  created() {
  
  },

  data() {
    return {
      itensOne: {
        title: "Adicionar",
        icon: "ti-pencil-plus"
      },

      buttonSubmit: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar'
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/client/search',
        name: 'Voltar'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
      },

      formData: {
        cpf: '',
        cnpj: '',
        size: '',
        name: '',
        name_f: '',
        surname: '',
        legal_nature: '',
        share_capital: '',
        main_activity: '',
        date_open: '',
        birth_date: '',
        headquarters: '',
        rg: '',
        type:1,
        status: 1,
        email: '',
        phone: '',
        members: [],
        secondary_activitys: [],
        cep: '',
        city: '',
        state: '',
        district: '',
        address: '',
        complement: '',
        country: '',
        company_status: ''
      },

      picked: 1,

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,

    }
  },


  methods: {
    checkType() {
      this.resetFormData()
      if (parseInt(this.formData.type) === 1) {
        this.picked = 1
      }
      if (parseInt(this.formData.type) === 2)
        this.picked = 2
    },

    resetFormData() {
      this.formData = {
        cpf: '',
        cnpj: '',
        size: '',
        name: '',
        name_f: '',
        surname:'',
        legal_nature: '',
        share_capital: '',
        main_activity: '',
        date_open: '',
        birth_date: '',
        headquarters: '',
        rg: '',
        type: this.formData.type,
        status: 1,
        emails: [],
        phones: [],
        members: [],
        secondary_activitys: [],
        cep: '',
        city: '',
        state: '',
        district: '',
        address: '',
        complement: '',
        country: '',
        company_status: ''
      }
    },

    searchCNPJ() {
      const dateCNPJ = {
        'type': this.formData.type,
        'cnpj': this.formData.cnpj
      }

      this.$store.state.loader.loading = true;
      this.$store.dispatch('searchCNPJ', dateCNPJ)
          .then((r) => {
            if (r.data.check) {
              toast.info(`Cliente já está cadastrado, ${this.me.name}!`, {timeout: 2000});
              this.$store.state.loader.loading = false
              this.resetFormData()
            } else {
              this.searchAPiCNPJ()
            }
          }).catch(() => {
        this.$store.state.loader.loading = false
      })
    },

    searchAPiCNPJ() {
      let cnpj = this.formData.cnpj.replace(/\D/g, '');
      const dateCNPJ = {
        'cnpj': cnpj
      }
      this.$store.dispatch('searchApiCNPJ', dateCNPJ)
          .then((r) => {
            this.completeInput(r.data)
          }).catch(() => {
      }).finally(() => {
        {
          this.$store.state.loader.loading = false
        }
      })
    },

    completeInput(data) {
      this.formData.name = data.company.name
      this.formData.name_f = data.alias
      this.formData.surname = this.formData.name
      this.formData.legal_nature = `${data.company.nature.id} - ${data.company.nature.text}`

      this.formData.share_capital = data.company.equity.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      this.formData.main_activity = `${data.mainActivity.id} - ${data.mainActivity.text}`
      this.formData.date_open = this.dateFormactBR(data.founded)
      this.formData.headquarters = data.head ? 'Sim' : 'Não'
      this.formData.company_status = `${data.status.id} - ${data.status.text}`
      this.formData.cep = data.address.zip
      this.formData.city = data.address.city
      this.formData.stateView = ESTADOS[data.address.state]
      this.formData.state = data.address.state
      this.formData.district = data.address.district
      this.formData.address = `${data.address.street}, ${data.address.number}`
      this.formData.country = data.address.country.name
      this.formData.complement = data.address.details
      this.formData.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`


     if (data.company.members.length > 0) {
        this.formData.members = []
        data.company.members.forEach(element => {
          this.formData.members.push({
            name: element.person.name,
            cpf_cnpj: element.person.taxId,
            age: element.person.age,
            qualification: `${element.role.id} - ${element.role.text}`
          })
        });
      }



      if (data.sideActivities.length > 0) {
        this.formData.secondary_activitys = []
        data.sideActivities.forEach(element => {
          this.formData.secondary_activitys.push({
            code: `${element.id}`,
            desc: `${element.text}`
          })
        });
      }




    },

    dateFormactBR(inputDate) {
      let dateArray = inputDate.split("-");
      return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    },

    searchCPF() {
      const dateCPF = {
        'type': this.formData.type,
        'cpf': this.formData.cpf
      }
      this.$store.dispatch('searchCPF', dateCPF)
          .then((r) => {
              if(r.data.check){
                toast.info(`Cliente já está cadastrado, ${this.me.name}!`, {timeout: 6000});
                this.resetFormData()
              }
          }).catch(() => {
      })
    },

    searchApiCEP() {
      this.resetFormAddresse()
      let cep = this.formData.cep.replace(/\D/g, '');
      const dateCEP = {
        'cep': cep
      }

      this.$store.dispatch('searchApiCEP', dateCEP)
          .then((r) => {
            this.formData.city = r.data.localidade,
                this.formData.state = r.data.uf,
                this.formData.stateView = ESTADOS[r.data.uf],
                this.formData.district = r.data.bairro,
                this.formData.address = r.data.logradouro,
                this.formData.complement = r.data.complemento
          }).catch(() => {
        this.$store.state.loader.loading = false;
      })
    },

    resetFormAddresse() {
          this.formData.city = '',
          this.formData.state = '',
          this.formData.stateView = '',
          this.formData.district = '',
          this.formData.address = '',
          this.formData.complement = ''
         this.formData.country = 'Brasil'
    },

    surnameOnkeypress(){
      this.formData.surname = this.formData.name
    },

    addClient(){

      if (!this.sendForm) {
          this.sendForm = true;
          this.$store.dispatch('storeClient', this.formData)
              .then(() => {
                this.showError = false
                this.sendForm = false
                toast.info(`Cliente adicionada com sucesso, ${this.me.name}!`, {timeout: 6000});
                this.resetFormData()
                this.resetFormAddresse()
                this.picked= 1
                this.formData.type=1

              }).catch((r) => {
            this.showError = true;
            this.sendForm = false
            this.errors = r.response.data.errors;
            toast.error("Seu formulário contém erros verificar!", {timeout: 6000});
          })
        }

    }



  },





    computed:{
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

      noResultsText() {
        return this.$store.state.var.noResultsText
      },

      noOptionsText() {
        return this.$store.state.var.noOptionsText
      },

      me() {
        return this.$store.state.auth.me
      },

  }






}

</script>