<template>
    <div class="painel modal-window p-4 pt-5">
        <error-component v-if="showError" :errors="errors"/>

        <div role="button" @click="[clear, this.$emit('close')]" class="btn-close"></div>
        <form>
            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.type}">
                    <label>Tipo:</label>
                    <MultiSelect v-model="formDataPayment.type" :options="selectPaymentType"/>
                </col-component>

                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.price}">
                    <label>Valor:</label>
                    <input-component v-money="MONEY" v-model="formDataPayment.price"/>
                </col-component>

            </row-component>

            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit"  name="Adicionar"/>

                </col-component>
            </row-component>
        </form>
    </div>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import MultiSelect from "@/components/form/MultiSelect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "AddPayment",
    components: {
        ErrorComponent,
        ButtonSubmit,
        InputComponent,
        MultiSelect,
        ColComponent,
        RowComponent
    },

    directives: {
        money: VMoney
    },

    data() {
        return {
            MONEY,

            formDataPayment: {
                type: 1,
                price: "",
                type_payment:""
            },

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('addPayment', this.formDataPayment)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.$emit('close')
                        toast.success('Pagamento adicionado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        clear() {
            this.formDataPayment = {
                type: 1,
                price: "",
                type_payment:""
            }
        }
    },

    computed: {
        selectPaymentType() {
            return this.$store.state.var.selectPaymentType
        }
    }
}
</script>

<style scoped>

.modal-window {
  width:450px;

}

.painel {
    position: relative;
    background: #FFFFFF;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}
</style>