import {createRouter, createWebHistory} from "vue-router"
import maproutes from "@/router/maproutes";
import store from "@/store"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: maproutes
})


router.beforeEach((to) => {
  if (to.meta.auth && !store.state.auth.authenticate) {
    return router.push({name: 'login'})
  }

  if (to.name === 'login' && store.state.auth.authenticate) {
    return router.push({name: '/box-show'})
  }
})

export default router
