import AdminComponent from "@/components/AdminComponent.vue";
import ClientCreate from "@/views/create/client-create.vue";


export default {
    path: '/',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'client-store', name: 'client-store', component: ClientCreate},
    ]
}