import AdminComponent from "@/components/AdminComponent.vue";
import BoxShow from "@/views/show/BoxShow.vue";

export default {
    path: '/',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: '', name: 'box-show', component: BoxShow},
    ]
}