import LoginView from "@/views/LoginView.vue";
import BoxPrint from "@/views/print/BoxPrint.vue";

import Box from "@/router/modules/box";
import Order from "@/router/modules/order";
import BoxPrintClose from "@/views/print/BoxPrintClose.vue";
import SalePrint from "@/views/print/SalePrint.vue";
import Historic from "@/router/modules/historic";
import Client from "@/router/modules/client";


const routes = [
    {
        path: '/',
        children: [
            {path: '/login', component: LoginView, name: 'login'},
            {path: '/box-print/:id', component: BoxPrint, name: 'box-print'},
            {path: '/box-print-close/:id', component: BoxPrintClose, name: 'box-print-close'},
            {path: '/sale-print-close/:id', component: SalePrint, name: 'sale-print-close'},
        ]
    },
    Box,
    Order,
    Historic,
    Client
]

export default routes